<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Pending Agent Applications</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Pending Agent Applications
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by Name, Unique Identifier, Email"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Phone / Mobile No."
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchUser"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                         @click="searchUser"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" :class="sortedClass('first_name')" @click="sortBy('first_name')"><strong>First Name</strong></th>
                  <th><strong>Last Name</strong></th>
                  <th><strong>Unique Identifier</strong></th>
                  <th><strong>Email</strong></th>
                  <th><strong>Phone / Mobile</strong></th>
<!--                  <th><strong>Date Of Birth</strong></th>-->
                  <th><strong>Business Name</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index" >
                    <td>
                      <router-link :to="{name:'user-agent-profile', params:{id:item.id}}"
                                   class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.first_name }}
                      </router-link>
                    </td>
                    <td class="text-left">
                      {{ item.last_name }}
                    </td>
                    <td>
                      {{ item.unique_identifier }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                    <td>
                      {{ item.phone || item.mobile }}
                    </td>
<!--                    <td>-->
<!--                      {{ item.formatted_date_of_birth ? item.formatted_date_of_birth : 'N/A' }}-->
<!--                    </td>-->
                    <td>
                      {{ item.business_name ? item.business_name : 'N/A' }}
                    </td>
                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="approveUser(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="green darken-2">fas fa-check-circle</v-icon>
                                  </span>
                                <span class="navi-text">Approve Application</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="declinedUser(item)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-window-close</v-icon>
                                  </span>
                                <span class="navi-text">Disapprove Application</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewDocument(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                <span class="navi-text">View attachments</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="users.length == 0" >
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllRequestUser"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>

          <!-- user models -->
          <v-dialog
              v-model="dialog"
              max-width="800"
              scrollable
          >
            <v-card>
              <v-toolbar dark>
                <v-card-title class="text-h5">
                  <span>{{ title }} Application Remarks</span>
                  <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row class="mt-2">
                    <v-col cols="12">
                      Enter your comment
                      <ckeditor
                          :config="editorConfig"
                          v-model="user.remarks"
                      >
                      </ckeditor>
                      <span class="text-danger" v-if="$v.user.remarks.$error">This Field is required</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="btn btn-primary"
                    dark
                    @click="approvedOrDeclinedUser()"
                    medium
                    :loading="btnLoading"
                >
                  Save
                </v-btn>
                <v-btn
                    text
                    dark
                    medium
                    @click="dialog = false">
                  Cancel
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <view-verification-document ref="view-verification-document"></view-verification-document>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";
import ViewVerificationDocument from "@/view/pages/view/user/request-for-agent/ViewVerificationDocument";

const user = new UserService();

export default {
  name: "Index",
  components: {ViewVerificationDocument},
  data() {
    return{
      loading: false,
      isLoading: false,
      approved: false,
      declined: false,
      btnLoading: false,
      dialog: false,
      users: [],
      total: null,
      perPage: null,
      page: null,
      user: {},
      title: '',
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      search:{
        info:'',
        is_active:'',
        mobile: '',
        is_agent: '1'
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  validations: {
    user:{
      remarks: {required}
    }
  },
  mounted() {
    this.getAllRequestUser();
  },
  computed: {
    sortedItems() {
      const list = this.users.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    viewDocument(user) {
      this.$refs['view-verification-document'].getUserSetting(user);
    },
    getAllRequestUser(){
      this.loading = true;
      user
          .getAllRequestUser(this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchUser(){
      this.getAllRequestUser();
    },
    approveUser(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.user.remarks = '';
            this.user = item;
            this.dialog = true;
            this.approved = true;
            this.title = 'Approved';
          }
        },
      });
    },
    declinedUser(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.user.remarks = '';
            this.user = item;
            this.dialog = true;
            this.declined = true;
            this.title = 'Disapprove';
          }
        },
      });
    },
    approvedOrDeclinedUser() {
      if(this.approved) {
        this.user.agent_application_status = 'approved';
        user
            .approvedUser(this.user.id, this.user)
            .then((response) => {
              this.getAllRequestUser();
              this.$snotify.success("Agent application approved");
              this.user.remarks = '';
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            }).finally(() => {
              this.dialog = false;
              this.approved = false
            });
      }
      if(this.declined) {
        this.$v.$touch()
        if (this.$v.$error) {
          setTimeout(() => {
            this.$v.$reset()
          }, 3000);
        } else {
          this.btnLoading = true;
          user
              .declinedUser(this.user.id, this.user)
              .then((response) => {
                this.getAllRequestUser();
                this.$snotify.success("Agent application disapproved ");
                this.user.remarks = '';
                this.btnLoading = false;
              })
              .catch((err) => {
                this.$snotify.error("Oops something went wrong");
                this.btnLoading = false;
              }).finally(() => {
            this.dialog = false;
            this.declined = false
          });
        }
      }
    }
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
