<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>View Verification Document</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row v-if="user_setting != null">
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_one && user_setting.file_type_one == 'image'">
              <a :href="user_setting.doc_one_file_path.real" target="_blank">
                <v-img
                    :src="user_setting.doc_one_file_path.real"
                    :lazy-src="user_setting.doc_one_file_path.real"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_one && user_setting.file_type_one == 'pdf'"
            >
              <a :href="user_setting.doc_one_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/pdf.png')"
                    :lazy-src="require('@/assets/media/pdf.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_one && user_setting.file_type_one == 'cvs'"
            >
              <a :href="user_setting.doc_one_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/cvs.png')"
                    :lazy-src="require('@/assets/media/cvs.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_one && user_setting.file_type_one == 'doc'"
            >
              <a :href="user_setting.doc_one_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/document.png')"
                    :lazy-src="require('@/assets/media/document.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_one == 'xls'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/xls.png')"
                    :lazy-src="require('@/assets/media/xls.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_two && user_setting.file_type_two == 'image'"
            >
              <a :href="user_setting.doc_two_file_path.real" target="_blank">
                <v-img
                    :src="user_setting.doc_two_file_path.real"
                    :lazy-src="user_setting.doc_two_file_path.real"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>

            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_two && user_setting.file_type_two == 'pdf'"
            >
              <a :href="user_setting.doc_two_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/pdf.png')"
                    :lazy-src="require('@/assets/media/pdf.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_two && user_setting.file_type_two =='cvs'"
            >
              <a :href="user_setting.doc_two_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/cvs.png')"
                    :lazy-src="require('@/assets/media/cvs.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_two && user_setting.file_type_two == 'doc'"
            >
              <a :href="user_setting.doc_two_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/document.png')"
                    :lazy-src="require('@/assets/media/document.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_two == 'xls'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/xls.png')"
                    :lazy-src="require('@/assets/media/xls.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three  && user_setting.file_type_three == 'image'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="user_setting.doc_three_file_path.real"
                    :lazy-src="user_setting.doc_three_file_path.real"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_three == 'pdf'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/pdf.png')"
                    :lazy-src="require('@/assets/media/pdf.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_three == 'cvs'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/cvs.png')"
                    :lazy-src="require('@/assets/media/cvs.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_three == 'doc'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/document.png')"
                    :lazy-src="require('@/assets/media/document.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_three && user_setting.file_type_three == 'xls'"
            >
              <a :href="user_setting.doc_three_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/xls.png')"
                    :lazy-src="require('@/assets/media/xls.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_four && user_setting.file_type_four == 'image'"
            >
              <a :href="user_setting.doc_four_file_path.real" target="_blank">
                <v-img
                    :src="user_setting.doc_four_file_path.real"
                    :lazy-src="user_setting.doc_four_file_path.real"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_four && user_setting.file_type_four == 'pdf'"
            >
              <a :href="user_setting.doc_four_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/pdf.png')"
                    :lazy-src="require('@/assets/media/pdf.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_four && user_setting.file_type_four == 'cvs'"
            >
              <a :href="user_setting.doc_four_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/cvs.png')"
                    :lazy-src="require('@/assets/media/cvs.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_four && user_setting.file_type_four == 'doc'"
            >
              <a :href="user_setting.doc_four_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/document.png')"
                    :lazy-src="require('@/assets/media/document.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
            <v-col
                class="d-flex child-flex"
                cols="6"
                v-if="user_setting.doc_four && user_setting.file_type_four == 'xls'"
            >
              <a :href="user_setting.doc_four_file_path.real" target="_blank">
                <v-img
                    :src="require('@/assets/media/xls.png')"
                    :lazy-src="require('@/assets/media/xls.png')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                </v-img>
              </a>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <h2>No Document Is Uploaded !!</h2>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            dark
            class="btn btn-primary"
            @click="closeDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewVerificationDocument",
  data(){
    return{
      dialog: false,
      user_setting: null,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getUserSetting(user) {
      this.dialog = true;
      this.user_setting = user.user_setting;
    }
  }
}
</script>

<style scoped>

</style>